import ApiBase from 'ApiRest/ApiBase'

export default new (class extends ApiBase {
  /**
   * @param {number} studentId
   * @param {Object} data
   * @returns {Promise}
   */
  put(studentId, data = {}) {
    const url = `/user/student/${studentId}/account-inactive`

    return this._PUT(url, data)
  }
})()
